import Fade from "react-reveal/Fade"
import React from "react"

import Container from "../../components/UI/Container"
import SectionHeading from "../../components/SectionHeading"
import SectionWrapper, {
  PrivacyText,
  PrivacyWrapper,
} from "./privacyPolicy.style"

const PrivacyPage = () => {
  return (
    <SectionWrapper>
      <Container noGutter>
        <Fade up delay={200}>
          <SectionHeading mb="64px" title="Privacy Policy" as="h1" />
        </Fade>
        <PrivacyWrapper>
          <Fade up delay={200}>
            <PrivacyText>
              <h2>1. Values</h2>
              <p>
                Privacy Policy Elegant Aesthetic Beauty Clinic Privacy Policy 1.
                Values Thank you for trusting us with some information about
                you. We take that trust seriously and we want you to know how we
                use your information and why. If you have queries about how we
                use your data, or comments or questions about this Policy,
                please do email us. The email address to use is set out in
                section 2 below. Policy updates: We keep this Policy under
                regular review, and this page may be updated from time to time.
                Please come back here to check the latest version. This Policy
                was last updated on the 26th January 2020.
              </p>
              <p></p>
              <h2>2. Who are we?</h2>
              <p>Name: Elegant Aesthetic Beauty Clinic</p>
              <p>Trading Name: Elegant Aesthetic Beauty Clinic Ltd</p>
              <p>Director(s): Shikha Rishi & James Tibbs</p>
              <p>Company Registration Number if applicable: 11549000</p>
              <p>
                Email address for official notices:
                booking@elegantaesthetic.co.uk
              </p>
              <p>Data retention period: 24 months</p>
              <p>
                Third party data processors names and their security policy
                links:
              </p>
              <p>
                All Star Marketing Club –
                https://allstarmarketingclub.com/privacy-policy/
              </p>
              <p>
                Apollo Database – internally created software located on secure
                server, however data may be shared with USA and UAE offices
              </p>
              <p>
                Artemis – internally created software located on secure server,
                however data may be shared with USA and UAE offices
              </p>
              <p>Facebook – https://www.facebook.com/privacy/explanation</p>
              <p>
                Hootsuite –
                https://hootsuite.com/legal/privacy#information-storage
              </p>
              <p>LinkedIn – https://www.linkedin.com/legal/privacy-policy</p>
              <p>Mailchimp – https://mailchimp.com/legal/privacy/</p>
              <p>
                Microsoft – https://privacy.microsoft.com/en-us/privacystatement
              </p>
              <p>
                Paypal – https://www.paypal.com/en/webapps/mpp/ua/privacy-full
              </p>
              <p>
                Pinkdog Services –
                http://pinkdogservices.co.uk/aboutus/privacy-policy/
              </p>
              <p>
                Sage – https://www.sage.co.uk/uk/hrpayrollhero/privacy-policy
              </p>
              <p>
                Survey Monkey –
                https://www.surveymonkey.com/mp/legal/privacy-policy/
              </p>
              <p>Twitter – https://twitter.com/en/privacy#update</p>
              <p>Webex – https://www.webex.com/cisco-privacy_full-text.html</p>
              <p>Mark Onslow – http://www.markonslow.co.uk/privacy-policy/</p>
              <p>
                Wildfire Creative & Marketing –
                http://www.wildfiremarketing.co.uk/privacy-policy-updates/
              </p>
              <p>
                If you would like further details on the third parties that we
                share data with and how they process data, please contact us for
                our most recent data audit information.
              </p>
              <h2>3. Words with specific meanings</h2>
              <p>
                In this Policy, there are words and phrases that have a specific
                meaning, or that we are using in a special way. They are:
              </p>
              Personal data – any information about an identifiable living human
              being. Process – we “process” your personal data when we do
              anything with it, which might include; collecting, recording,
              organising, storing, adapting, altering, retrieving, using,
              combining, disclosing, or deleting it. Special category data –
              personal data revealing racial or ethnic origin, political
              opinions, religious or philosophical beliefs, trade union
              membership, sex life or sexual orientation, health, genetic or
              biometric data.
              <h2>4. What this policy describes</h2>
              This policy describes how we will collect and use personal data
              about you. We process information about:
              <p>Prospects – potential customers or referrers;</p>
              <p>Customers – who have bought goods or services from us;</p>
              <p>
                Suppliers and Associates – suppliers or potential suppliers of
                goods or services to us;
              </p>
              <p>
                Affiliates – who have signed up to our affiliate scheme (if we
                have one).
              </p>
              <h2>5. What information do we process, and why?</h2>
              <p>Prospects</p>
              <p>
                Most of the information we process comes from you. We process it
                so we can reply to you, and when you contact us again we know
                what you asked before, what you were sent, and what you told us.
              </p>
              <p>
                Typically, we are your collecting name, contact details, how we
                came across you, background information (from you or published
                by you on social media or freely accessible on the internet),
                information on why you might be interested in our products or
                services or a relevant contact for our business.
              </p>
              <p>
                If you sign up to a newsletter list, you will be sent what you
                asked for. We normally operate ‘double opt-in’ lists and you
                will need to reconfirm your subscription before anything is
                sent. You can unsubscribe at any time by clicking the
                unsubscribe button on any email.
              </p>
              <p>
                You are not automatically subscribed to any other lists but may
                be invited to join an appropriate one.
              </p>
              <p>
                If we email you individually using our own email system or
                respond to an email sent to us at any of our business email
                addresses, a copy of that email may also be stored.
              </p>
              <p>
                If you make an enquiry via our website, we will keep details of
                that enquiry and response for our data retention period (section
                2, Table, above).
              </p>
              <p>
                We do not routinely keep special category data. To the extent we
                hold this, it was supplied or made publicly available by you.
              </p>
              <p>Customers</p>
              <p>
                Once you buy something from us, we will collect information from
                you at the point of sale.
              </p>
              <p>
                This will include the information we collect from Prospects
                (above). We collect your email address, phone number and postal
                address so we can provide what we have contracted to, invoice
                you and keep proper records of our business relationship.
              </p>
              <p>
                We process your data to support the delivery the goods and
                services you have bought. We keep records of the goods/services
                provided to you, and information you give us, so we can support
                you when needed and advise you of any additional services you
                may need.
              </p>
              <p>Financial and credit card details</p>
              <p>
                We do not receive or store your credit card details. Credit card
                payments are handled by an external secure processor in
                accordance with their data security policies (see section 2,
                Table, above).ß
              </p>
              <p>
                We receive limited information from our processor for us to tie
                up your payment with your invoice.
              </p>
              <p>
                If you pay us by BACS or direct transfer, we know only what the
                bank tells us, which is usually the name of the person who paid
                us and how much and the reference number.
              </p>
              <p>
                We do not routinely keep credit scores nor use credit reference
                agencies.
              </p>
              <p>
                When we are processing data about you on behalf of a customer,
                we are operating under the banner of our customer’s data privacy
                policy. We will refer any enquiry from you to them, as they are
                the ‘data controller’ responsible for dealing with your query.
                But we will support that by providing relevant information to
                our customer for passing to you.
              </p>
              <h3>Supplier and Associates</h3>
              <p>
                We collect information on potential and actual suppliers and
                associates. This is mostly provided by you, but we do add to it
                the same kind of data we use for Prospects (see above).
              </p>
              <p>
                If you become a supplier or associate, we keep a copy of the
                contract between us and your bank details so we can pay you. We
                also keep a record of invoices/payments for accounting purposes.
              </p>
              <p>
                We keep a record of the work you undertook for us/our clients
                along with any comments, reviews or suggestions about that work
                including complaints (if any) and their resolution.
              </p>
              <p>
                This information is all needed to manage our customer
                relationships and our supply chain.
              </p>
              <h3>Affiliate</h3>
              <p>
                If we set up an affiliate scheme, affiliate data will be held in
                accordance with this policy. We will ask you for information
                when you apply and that will be kept to administer the affiliate
                scheme.
              </p>
              <h2>6. Newsletters and automated emails</h2>
              <p>
                We may monitor who opens what in our newsletter lists, and
                pre-set sequences of information we send you. We do this, so we
                can see if content is popular and generate more of it, or if it
                is not read. There may be sub-routines that trigger if you click
                on links or articles. These are designed to offer you more
                information about things you are interested in. You can
                unsubscribe from these sequences at any time. Existing customers
                may receive emails about specific offers relating to things you
                have already purchased. You can unsubscribe from these at any
                time. From time to time, we contact individual email newsletter
                subscribers, but it is extremely rare. This would normally be if
                something odd were going on and we wanted to check you could see
                and use the content or find out what was causing a problem.
              </p>
              <h2>7. Data sharing – 3rd parties</h2>
              <p>
                We do not sell or exchange your personal data with organisations
                who may want to sell you something or use your data for research
                or other purposes.
              </p>
              <h3>a. Platforms</h3>
              <p>
                We keep details of the software platforms we use to run our
                business (as per section 2, Table, above). If you would like
                further details on all the platforms we use, please contact us.
              </p>
              <h3>b. People</h3>
              <p>
                We have an outsourced support team for our own business which
                may include Virtual Assistants, Web Designers, IT support, Sales
                and Marketing, Accounting and more. They have limited access to
                your data, where the service they provide to us means they need
                it. For example, if our IT support wants to check the
                functionality of a laptop or back up, they may need temporary
                access to information that may include something about you. For
                example, if we invoice you, our Accountant needs to process the
                information in the invoice. Your information/advice is held in
                the strictest confidence. Our team are all contracted to strict
                confidentiality clauses.
              </p>
              <h2>8. Where is your data located?</h2>
              <p>
                Like most small businesses, we use mainstream packages for some
                of our data processing from our customer records, to email, to
                accounting. This means that some of your data may be held in the
                EEA, and some may be held in services in the USA (with suitable
                data privacy shields) or elsewhere. We have picked mainstream
                suppliers with appropriate security standards. We also use
                in-house created software tools – Artemis and Apollo which hold
                customer and prospect data. This data is stored in the UK,
                however at times data may be shared with our USA and UAE offices
                for legitimate interest reasons.
              </p>
              <h3>9. Retention periods</h3>
              <p>
                Your information will be kept for the length of time set out in
                our retention period (see section 2, Table, above). We need to
                keep customer information long enough to satisfy HMRC and our
                insurers. We keep information on prospective customers long
                enough to make our sales enquiry system effective. If you
                subscribed to a newsletter or updates list, you will remain on
                the list(s) you joined until you unsubscribe from that list.
              </p>
              <h2>10. Your rights</h2>
              <p>
                ou have the right to know what information we are collecting on
                you, and to amend it if it is inaccurate. If you feel for some
                reason we have information we should not be keeping, or it is
                out of date or otherwise wrong, please let us know and we will
                take appropriate action. Most of the information we hold is not
                based on your individual consent but is based on our needing the
                information to run our business and provide our products and
                services. If you want to know what information we have about you
                (if any) email us at the email address set out above and give us
                your name, email address(es) and we will happily do a search and
                let you know what information we hold on you and how we are
                using it/have used it. You have a “right to be forgotten” – but
                that does have some legal limits to it. If you want us to remove
                information about you, let us know. If you have been a customer,
                we may not be able to remove all data as we will have to ensure
                that we can continue to comply with legal, accounting, taxation
                and our insurer’s requirements.
              </p>
              <h2>11. Complaints</h2>
              <p>
                If you have a complaint about the way we are handling your
                information or how we have responded to a request for
                information or removal, you can take this up in the first
                instance by emailing us at the email address set out above. If
                we can’t sort it out, the relevant supervisory authority for us
                is the Information Commissioner for the UK.
              </p>
              <h2>12. Cookies</h2>
              <p>
                Elegant Aesthetic Beauty Clinic engages third parties, which use
                Web beacons, images, and scripts, to help better manage content
                on Elegant Aesthetic Beauty Clinic’s Web site. Elegant Aesthetic
                Beauty Clinic does not provide Personal Information to the third
                parties but may tie the information gathered from third party
                tracking to Visitors’ Personal Information for marketing
                purposes.
              </p>
            </PrivacyText>
          </Fade>
        </PrivacyWrapper>
      </Container>
    </SectionWrapper>
  )
}

export default PrivacyPage
