import React from "react"

import Layout from "../containers/Layout"
import PrivacyPolicy from "../containers/PrivacyPolicy"
import SEO from "../components/SEO"
import { ContentWrapper } from "../containers/elegant.style"

const PrivacyPage = () => {
  return (
    <>
      <SEO
        title="Elegant Privacy Policy"
        shortDesc="Elegant Beauty Clinic"
        description="Description Here"
      />
      <Layout>
        <ContentWrapper>
          <PrivacyPolicy />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default PrivacyPage
